import { useEffect, useState } from 'react'
import {
  Categories,
  Panel,
  withConfirmation,
  Modal,
  useLoading,
  Page404,
  Page403,
  withValidation,
  ModalContainer
} from 'simple-core-ui'

import BudgetsTab from './budgets/BudgetsTab'
import OverviewTab from './overview/OverviewTab'
import InvoicesTab from './invoices/InvoicesTab'
import { Tasks } from './tasks'
import { Events } from './events'
import VendorsTab from './vendors/VendorsTab'
import { RelatedMatters } from './settings/RelatedMatters'
import SettingsTab from './settings/SettingsTab'
import LegalRequestsTab from './legalRequests/LegalRequestsTab'
import MatterUpdates from './MatterUpdates'
import AttachmentsTabContainer from './files/AttachmentsTabContainer'
import ExternalFileManagementContainer from './files/ExternalFileManagement/ExternalFileManagementContainer'
import s from './MatterDetailContainer.scss'
import { makeGetRequest, makeFormDataPost, makePostRequest } from 'utils/api'
import { useDispatch, useSelector } from 'react-redux'
import { useImmer } from 'use-immer'
import { serializeAttrs } from './overview/serializers'
import { MatterSelect } from 'common/Selects'
import { MATTER_STATUS } from 'matters/constants'
import { hasModule } from 'utils/helpers'
import { MODULE, SCOPE } from 'utils/constants'
import CONTACT_ACT from '../../contacts/actions'

import { ScopedReviewableBanner } from 'reviews'

import { MatterHeader, ReopenMatterModal } from './MatterHeader'
import cn from 'classnames'
import BannerContainer from './Banner/BannerContainer'
import HeaderLoadingSkeleton from './Skeleton'
import ThemeContext from 'context/ThemeContext'
import { useLocation, Link, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import { Calendar } from './calendar/CalendarTab'
import qs from 'query-string'
import { ContactsSelect } from 'contacts'
import APP_ACT from 'app/actions'
import { hasMatterLead } from 'contacts/utils'
import { DraftPreview } from './files/MatterAttachmentsTab/DocumentDrafting'
import { INITIAL_DRAFTING_CONTEXT } from './files/MatterAttachmentsTab/DocumentDrafting/constants'
import DocumentDraftingContext from './context'
import { MatterStatusUpdates } from './EnhancedMatterUpdates'

const START_STICKY_HEADER_Y = 152

const headerStyles = {
  position: 'fixed',
  top: 40,
  paddingTop: 4,
  backgroundColor: '#fff',
  width: 'calc(100% - 50px)',
  left: 50,
  paddingLeft: 50,
  paddingRight: 40,
  zIndex: 19,
  boxShadow: '0 0 4px 0 #D4D4D4'
}

const user = window.credentials.user

const { allow_vendor_collaboration_matter_updates: hasEnhancedMatterStatusUpdates } =
  window.serverContext.get('data_for_react') ?? {}

function MatterDetails(props) {
  const [tabState, setTabState] = useState('')
  const [stickyHeaderStyle, setStickyHeaderStyle] = useState(null)
  const [settings, setSettings] = useState(null)
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false)
  const [mergeTarget, setMergeTarget] = useState(null)
  const [pageError, setPageError] = useState(null)
  const rDispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()
  const [isAttrsLoading, withLoadingLocksAttrs] = useLoading()
  const [isSettingsLoading, withLoadingLocksSettings] = useLoading()
  const [matterStatus, setMatterStatus] = useState('')
  const [matterName, setMatterName] = useState('')
  const [pendingRequiredFields, setPendingRequiredFields] = useState([])
  const [reopenMatterModalVisible, setReopenMatterModalVisible] = useState(false)
  const surveysStatus = useSelector(state => state?.surveyConfig?.sharedEvaluators.status)
  const contacts = useSelector(state => state?.contacts)
  const [showAssignLeadModal, setShowAssignLeadModal] = useState(false)
  const [selectedMatterLeadContact, setSelectedMatterLeadContact] = useState(null)
  const roles = contacts?.roles

  const [showDraftingSidebar, setShowDraftingSidebar] = useState(false)
  const [showDraftingPreview, setShowDraftingPreview] = useState(false)
  const [draftingContext, setDraftingContext] = useState(INITIAL_DRAFTING_CONTEXT)

  const [attributes, setAttributes] = useImmer({})
  const location = useLocation()
  const { id } = useParams()
  const queryParams = qs.parse(location.search)
  const [updateId, setUpdateId] = useState(parseInt(queryParams?.update_id, 10) || null)
  useEffect(() => {
    setMatterStatus(settings?.status?.slug)
  }, [settings])

  useEffect(() => {
    handleAttrValidation()
    setMatterName(attributes.generalAttributes?.matter_short_name?.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes])

  useEffect(() => {
    handleAttrValidation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts?.assignedContacts])

  const handleScroll = () => {
    const currentScrollY = window.scrollY
    if (currentScrollY >= START_STICKY_HEADER_Y) {
      !stickyHeaderStyle && setStickyHeaderStyle(headerStyles)
    } else {
      setStickyHeaderStyle(null)
    }
  }

  const hasMatterLeadContact = () => {
    const assignedContacts = contacts.assignedContacts || []

    return assignedContacts.some(contact => {
      const contactRoles = contact.roles || []
      return hasMatterLead(contactRoles)
    })
  }

  const handleAttrValidation = () => {
    let fields = []
    Object.keys(attributes).forEach(key => {
      if (key === 'generalAttributes') return
      attributes[key].forEach(section => {
        section.attributes.forEach(attrGroup => {
          attrGroup.attributes.forEach(attr => {
            const { required, label, value } = attr
            if (required && (value === null || value === '' || +value?.value === -1)) {
              fields.push(label)
            }
          })
        })
      })
    })

    const hasMatterLeadRoleInAssignedContacts = hasMatterLeadContact()

    if (!hasMatterLeadRoleInAssignedContacts) {
      fields.push('Matter Lead')
    }
    setPendingRequiredFields(fields)
    return fields.length
  }

  const bannerStyles = {
    width: 'calc(100% + 82px)',
    marginBottom: 24,
    position: 'static',
    ...(pendingRequiredFields.length ? { backgroundColor: '#FFF8FB', color: '#691526' } : {})
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const matterId = id

  useEffect(() => {
    const parsedQuery = qs.parse(location.search)

    const tabConfig = {
      overview: null,
      tasks: MODULE.TASKS,
      events: MODULE.EVENT_MANAGEMENT
    }

    const requestedTab = parsedQuery.tab || 'overview'
    const requiredModule = tabConfig[requestedTab]

    if (requiredModule && !hasModule(requiredModule)) {
      setTabState('overview')
    } else {
      setTabState(requestedTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Redux state update to keep banner and publish button synced
    rDispatch({
      type: CONTACT_ACT.ASSIGNED_CONTACTS_FETCH_REQUESTED,
      payload: {
        scope: SCOPE.MATTER,
        scopeId: matterId
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!tabState) return

    const parsedQuery = qs.parse(window.location.search)
    if (tabState !== parsedQuery.tab) {
      delete parsedQuery.subtab
    }
    const mergedQuery = {
      ...parsedQuery,
      ...(tabState === 'tasks' ? parsedQuery : {}),
      tab: tabState
    }
    const queryString = qs.stringify(mergedQuery)
    window.history.replaceState(null, '', `?${queryString}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState])

  useEffect(() => {
    async function fetchAttrs() {
      const url = `/manage/matters/custom_attributes/${matterId}/`
      try {
        const response = await withLoadingLocksAttrs(makeGetRequest(url))

        setAttributes(draft => {
          const {
            matter_attribute_sections,
            other_attribute_sections,
            generalAttributes
          } = serializeAttrs(response.attributes, matterId)

          draft.customAttributes = matter_attribute_sections
          draft.otherAttributes = other_attribute_sections
          draft.generalAttributes = generalAttributes
        })
      } catch (e) {
        if (e.response.status === 404) {
          return setPageError(404)
        }
        if (e.response.status === 403) {
          return setPageError(403)
        }

        rDispatch({ type: 'API_ERROR', error: e })
      }
    }
    async function fetchSettings() {
      try {
        if (!matterId) return
        const { settings } = await withLoadingLocksSettings(
          makeGetRequest(`/manage/matters/settings/${matterId}/`)
        )
        setSettings(settings)
      } catch (error) {
        if (error.response.status === 404) {
          return setPageError(404)
        }
        if (error.response.status === 403) {
          return setPageError(403)
        }

        rDispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    }
    fetchAttrs()
    fetchSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rDispatch, matterId])

  const updateAttributeValue = ({ attr, value, sectionIndex, panel, attrGroupIndex }) => {
    setAttributes(draft => {
      draft[panel][sectionIndex].attributes[attrGroupIndex].attributes[0].value = value

      if (attr.isGeneral) {
        draft.generalAttributes[attr.name].value = value
      }
    })
  }

  const matterStatusUpdateProps = {
    user: user,
    matterId: matterId,
    readOnly: matterStatus === MATTER_STATUS.CLOSED || !settings?.permissions?.has_edit_capability
  }

  const updateConditionalAttributeValue = ({
    attr,
    value,
    sectionIndex,
    panel,
    attrGroupIndex,
    relationId,
    group,
    attrIndex = 0
  }) => {
    setAttributes(draft => {
      // find all ids in the relation that we want to remove from form state
      // we want to remove ids of all attrs after current one
      const indexes2Clear = group.attributes
        .map((a, index) => ({ id: a.id, index }))
        .filter((a, i) => {
          return i > attrIndex
        })

      indexes2Clear.forEach(({ id, index }) => {
        draft[panel][sectionIndex].attributes[attrGroupIndex].attributes[index].value = null
      })

      draft[panel][sectionIndex].attributes[attrGroupIndex].attributes[attrIndex].value = value
    })
  }

  const getFilesContent = () => {
    if (hasModule(MODULE.EXTERNAL_DMS)) {
      return <ExternalFileManagementContainer matterId={matterId} />
    }
    return (
      <DocumentDraftingContext.Provider
        value={{
          showDraftingPreview,
          setShowDraftingPreview,
          showDraftingSidebar,
          setShowDraftingSidebar,
          draftingContext,
          setDraftingContext
        }}
      >
        <AttachmentsTabContainer
          domProps={{
            matterStatus: settings?.status?.slug,
            canEdit: settings?.permissions?.has_edit_capability
          }}
        />
      </DocumentDraftingContext.Provider>
    )
  }

  const tabs = {
    overview: (
      <OverviewTab
        isAttrsLoading={isAttrsLoading}
        isSettingsLoading={isSettingsLoading}
        customAttributes={attributes.customAttributes}
        otherAttributes={attributes.otherAttributes}
        updateAttributeValue={updateAttributeValue}
        updateConditionalAttributeValue={updateConditionalAttributeValue}
        matterId={matterId}
        settings={settings}
        setSettings={setSettings}
      />
    ),
    budgets: (
      <BudgetsTab
        scopeId={matterId}
        matterName={matterName}
        readOnly={
          // TODO remove is test property and fetch all the correct permissions inside the test
          props?.isTest
            ? false
            : matterStatus === MATTER_STATUS.CLOSED || !settings?.permissions?.has_edit_capability
        }
      />
    ),
    calendar: <Calendar scopeId={matterId} />,
    files: <Panel isBodyOnly>{getFilesContent()}</Panel>,
    invoices: <InvoicesTab scopeId={matterId} lineItemCount={settings?.summary?.line_count} />,
    ...(settings?.has_legal_request
      ? {
          requests: <LegalRequestsTab scopeId={matterId} />
        }
      : {}),
    tasks: (
      <Tasks
        scopeId={matterId}
        scopeName={matterName}
        readOnly={
          matterStatus === MATTER_STATUS.CLOSED || !settings?.permissions?.has_edit_capability
        }
      />
    ),
    events: (
      <Events
        scopeId={matterId}
        scopeName={matterName}
        readOnly={
          matterStatus === MATTER_STATUS.CLOSED || !settings?.permissions?.has_edit_capability
        }
      />
    ),
    updates: (
      <Panel isBodyOnly>
        {hasEnhancedMatterStatusUpdates ? (
          <MatterStatusUpdates {...matterStatusUpdateProps} />
        ) : (
          <MatterUpdates {...matterStatusUpdateProps} />
        )}
      </Panel>
    ),
    related: (
      <RelatedMatters
        scopeId={matterId}
        readOnly={
          matterStatus === MATTER_STATUS.CLOSED || !settings?.permissions?.has_edit_capability
        }
      />
    ),
    vendors: matterName && (
      <VendorsTab
        matterName={matterName}
        surveysStatus={surveysStatus}
        scopeId={matterId}
        hasPurchaseOrders={settings?.has_purchase_orders}
        readOnly={
          matterStatus === MATTER_STATUS.CLOSED || !settings?.permissions?.has_edit_capability
        }
      />
    ),
    settings: (
      <SettingsTab
        reviewerConfigId={settings?.reviews.invoice_reviewer_config_id}
        readOnly={
          !settings?.permissions?.has_edit_capability || matterStatus === MATTER_STATUS.CLOSED
        }
        scopeId={matterId}
      />
    )
  }

  const openMergeMatterModal = () => {
    setIsMergeModalOpen(true)
  }

  const cloneMatter = withConfirmation(
    async () => {
      try {
        const response = await makeFormDataPost('/manage/matters/clone/', { object_id: matterId })
        window.location.href = `/v2/matters/${response['clonedObjectId']}`
      } catch (e) {
        rDispatch({ type: 'API_ERROR', error: e })
      }
    },
    { title: 'You are duplicating the matter', text: 'Do you want to continue?' }
  )

  const deleteMatter = withConfirmation(
    async () => {
      try {
        await makeFormDataPost(`/manage/matters/${matterId}/delete`)
        window.location.href = '/v2/matters/list'
      } catch (e) {
        rDispatch({ type: 'API_ERROR', error: e })
      }
    },
    { title: 'You are deleting the matter', text: 'Do you want to continue?' }
  )

  const resetMatterLeadModal = () => {
    setShowAssignLeadModal(false)
    setSelectedMatterLeadContact(null)
  }

  const withMatterLeadValidation = (validateCb, cb) => {
    return () => {
      const isValid = validateCb()
      if (!isValid) {
        setShowAssignLeadModal(true)
      } else {
        return cb()
      }
    }
  }

  const closeMatter = withMatterLeadValidation(
    hasMatterLeadContact,
    withConfirmation(
      async () => {
        let scheduleSurveys = false
        if (surveysStatus === 'enabled') {
          const willApprove = await swal({
            title: 'Send vendor surveys on close?',
            text:
              'A survey will be created and an email with a link to the survey will be sent to all evaluators within the next day.',
            buttons: ['No', 'Yes'],
            icon: 'warning'
          })

          if (willApprove) {
            scheduleSurveys = true
          }
        }

        try {
          await makeFormDataPost('/manage/matters/change_status/', {
            pk: matterId,
            status: MATTER_STATUS.CLOSED,
            schedule_surveys: scheduleSurveys
          })
          window.location.reload()
        } catch (e) {
          rDispatch({ type: 'API_ERROR', error: e })
        }
      },
      { title: 'You are closing the matter', text: 'Do you want to continue?' }
    )
  )

  const scopedRequestConfig = (scope, matterId) =>
    ({
      [SCOPE.MATTER]: { m: matterId, l: 'true' }
    }[scope])

  const handleMatterLeadUpdate = async () => {
    try {
      const matterLeadRole = roles.find(role => role.systemName === '_ml')
      selectedMatterLeadContact?.roles.push(matterLeadRole)

      const response = await withLoadingLocks(
        makePostRequest(
          `/manage/contacts/matter/${matterId}/${selectedMatterLeadContact?.id}/assign/`,
          {
            roles: selectedMatterLeadContact?.roles,
            role_timestamp: selectedMatterLeadContact?.roleTimestamp
          }
        )
      )

      rDispatch({
        type: CONTACT_ACT.ASSIGNED_CONTACTS_FETCH_REQUESTED,
        payload: {
          scope: SCOPE.MATTER,
          scopeId: matterId
        }
      })

      rDispatch({
        type: APP_ACT.PUSH_NOTIFICATION,
        payload: {
          title: 'Success',
          message: 'Matter Lead successfully assigned',
          level: 'success'
        }
      })
    } catch (e) {
      rDispatch({ type: 'API_ERROR', error: e })
    } finally {
      resetMatterLeadModal()
    }
  }

  const reopenMatter = () => setReopenMatterModalVisible(true)

  const changeTemplate = withConfirmation(
    () => {
      window.location.href = `/new/matters/templates/select?matterId=${matterId}&numberOfTemplates=${settings.templates?.client_templates_count}&latestTemplateId=${settings?.latest_template_id}`
    },
    { title: 'You are changing the template', text: 'Do you want to continue?' }
  )

  const handleMergeMatter = async () => {
    try {
      const response = await withLoadingLocks(
        makeFormDataPost('/manage/matters/combine/', {
          source_matter_id: matterId,
          target_matter_id: mergeTarget.value
        })
      )
      window.location.href = `/v2/matters/${mergeTarget.value}`
    } catch (e) {
      rDispatch({ type: 'API_ERROR', error: e })
    }
  }

  const publishMatter = withValidation(
    handleAttrValidation,
    async () => {
      try {
        await makeFormDataPost('/manage/matters/change_status/', {
          pk: matterId,
          status: MATTER_STATUS.OPEN
        })
        window.location.reload()
      } catch (e) {
        rDispatch({ type: 'API_ERROR', error: e })
      }
    },
    { text: 'One or more required fields are missing a value.', title: 'Missing Required Values' }
  )

  const handleAction = async action => {
    switch (action.value) {
      case 'clone':
        return cloneMatter()
      case 'close':
        return closeMatter()
      case 'reopen':
        return reopenMatter()
      case 'change_template':
        return changeTemplate()
      case 'merge':
        return openMergeMatterModal()
      case 'delete':
        return deleteMatter()
      case 'publish':
        return publishMatter()
      default:
        return
    }
  }

  if (pageError === 404) {
    return <Page404 flag="Matter" />
  }

  if (pageError === 403) {
    const parsedQuery = qs.parse(window.location.search)
    if (parsedQuery.tab === 'events') {
      window.location.href = '/'
      return
    }

    return <Page403 flag="page" />
  }

  const showReviewBanner =
    settings?.reviews?.matter_is_in_pre_review || settings?.reviews?.matter_is_in_review

  const showHeader =
    !settings?.templates?.processing_template_change &&
    settings?.permissions?.has_edit_capability &&
    settings?.status?.is_status_changeable &&
    settings?.permissions?.can_change_matter_status &&
    matterStatus === MATTER_STATUS.DRAFT

  const getBreadcrumbLink = () => {
    if (location.search.includes('bulkConfig')) {
      return <Link to={`/v2/bulk#${location.search}`}>{'<< Return to Bulk Actions'}</Link>
    }
    if (location.search.includes('fromTasksWorkbench')) {
      return <Link to="/v2/tasks">{'<< Return to Tasks'}</Link>
    }
    return (
      <a
        href={`/v2/matters/list${
          location.search.includes('fromMatterList') &&
          window.localStorage.getItem('savedMatterList')
            ? '#' + window.localStorage.getItem('savedMatterList')
            : ''
        }`}
      >
        {'<< Return to Matters'}
      </a>
    )
  }

  return (
    <ThemeContext.Consumer>
      {({ state }) => (
        <>
          {showDraftingPreview ? (
            <DocumentDraftingContext.Provider
              value={{
                showDraftingPreview,
                setShowDraftingPreview,
                showDraftingSidebar,
                setShowDraftingSidebar,
                draftingContext,
                setDraftingContext
              }}
            >
              <DraftPreview
                showDraftingPreview={showDraftingPreview}
                setShowDraftingPreview={setShowDraftingPreview}
                showDraftingSidebar={showDraftingSidebar}
                setShowDraftingSidebar={setShowDraftingSidebar}
                draftingContext={draftingContext}
                setDraftingContext={setDraftingContext}
              />
            </DocumentDraftingContext.Provider>
          ) : (
            <>
              {hasModule('has_matter_reviews')
                ? showReviewBanner && (
                    <ScopedReviewableBanner
                      reviewId={settings?.reviews?.review_id}
                      pendingRequiredFields={pendingRequiredFields}
                      classNameMap={{ banner: bannerStyles, text: s.bannerText }}
                    />
                  )
                : showHeader && (
                    <BannerContainer
                      publishMatter={publishMatter}
                      status={matterStatus}
                      scopeId={matterId}
                      pendingRequiredFields={pendingRequiredFields}
                      classNameMap={{ banner: bannerStyles, text: s.bannerText }}
                    />
                  )}
              <div
                className={cn(s.container, {
                  [s.marginTop120]: stickyHeaderStyle
                })}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {getBreadcrumbLink()}
                </div>
                <div
                  style={stickyHeaderStyle}
                  className={cn('filter-container', s.filterContainer, {
                    [s.sticky]: stickyHeaderStyle,
                    [s.isMenuExpanded]: stickyHeaderStyle && state.isMenuExpanded
                  })}
                >
                  {isSettingsLoading || isAttrsLoading ? (
                    <HeaderLoadingSkeleton />
                  ) : (
                    <MatterHeader
                      matterName={matterName}
                      handleAction={handleAction}
                      settings={settings}
                      scopeId={matterId}
                    />
                  )}

                  <Categories
                    style={{ flex: '1 0 100%' }}
                    categories={[
                      { id: 'overview', content: 'Overview', isActive: 'overview' === tabState },
                      ...(hasModule('has_budgets')
                        ? [{ id: 'budgets', content: 'Budgets', isActive: 'budgets' === tabState }]
                        : []),
                      ...(hasModule('has_matters')
                        ? [
                            {
                              id: 'calendar',
                              content: 'Calendar',
                              isActive: 'calendar' === tabState
                            }
                          ]
                        : []),
                      { id: 'files', content: 'Files', isActive: 'files' === tabState },
                      { id: 'invoices', content: 'Invoices', isActive: 'invoices' === tabState },
                      ...(hasModule(MODULE.TASKS)
                        ? [
                            {
                              id: 'tasks',
                              content: 'Tasks',
                              isActive: 'tasks' === tabState
                            }
                          ]
                        : []),
                      ...(hasModule(MODULE.EVENT_MANAGEMENT)
                        ? [
                            {
                              id: 'events',
                              content: 'Events',
                              isActive: 'events' === tabState
                            }
                          ]
                        : []),
                      ...(settings?.has_legal_request
                        ? [
                            {
                              id: 'requests',
                              content: 'Legal Requests',
                              isActive: 'requests' === tabState
                            }
                          ]
                        : []),
                      {
                        id: 'related',
                        content: 'Related Matters',
                        isActive: 'related' === tabState
                      },
                      { id: 'updates', content: 'Updates', isActive: 'updates' === tabState },
                      { id: 'vendors', content: 'Vendors', isActive: 'vendors' === tabState },
                      { id: 'settings', content: 'Settings', isActive: 'settings' === tabState }
                    ]}
                    clickCb={t => {
                      const parsedQuery = qs.parse(window.location.search)
                      delete parsedQuery.subtab
                      const queryString = qs.stringify(parsedQuery)

                      window.history.replaceState(null, '', `?${queryString}`)
                      setTabState(t)
                    }}
                  />
                </div>
                <div data-testid="budgets_container">{tabs[tabState]}</div>
              </div>
            </>
          )}
          <Modal
            size="sm"
            cancelCb={() => {
              setMergeTarget(null)
              setIsMergeModalOpen(false)
            }}
            isVisible={isMergeModalOpen}
            title="Merge Matter"
            confirmText="Submit"
            cancelText="Cancel"
            confirmCb={handleMergeMatter}
            isDisabled={!mergeTarget}
            content={
              <div>
                <p className={s.mergeDescription}>
                  Select a matter to merge to. You cannot undo matter merges.{' '}
                  <a href="https://zendesk.simplelegal.com/hc/en-us/articles/360053849534-Merging-a-Duplicate-Matter">
                    Learn more
                  </a>
                </p>
                <MatterSelect
                  ariaLabel="Select Matter"
                  isPortal={false}
                  value={mergeTarget}
                  onChange={setMergeTarget}
                  placeholder="Search Matter"
                  paginated
                />
              </div>
            }
          />
          {showAssignLeadModal && (
            <ModalContainer
              id="assign"
              title="Assign a Matter Lead"
              subtitle="Before closing a matter, you must select a contact to assign the
                        role of matter lead."
              hasNewButtons
              size="sm"
              cancelCb={() => resetMatterLeadModal()}
              confirmText="Update"
              confirmCb={handleMatterLeadUpdate}
              isDisabled={!selectedMatterLeadContact}
              content={
                <ContactsSelect
                  requestConfig={{ params: scopedRequestConfig(SCOPE.MATTER, matterId) }}
                  value={selectedMatterLeadContact}
                  placeholder="Select a new contact..."
                  onChange={selectedMatterLeadContact =>
                    setSelectedMatterLeadContact(selectedMatterLeadContact)
                  }
                  hasMatterLead
                />
              }
            />
          )}
          {reopenMatterModalVisible && (
            <ReopenMatterModal
              matterId={matterId}
              onCancel={() => setReopenMatterModalVisible(false)}
            />
          )}
        </>
      )}
    </ThemeContext.Consumer>
  )
}

export default MatterDetails
