import pluralize from 'pluralize'
import s from './MatterUpdatesHeader.scss'
import { Button, TextInput } from 'simple-core-ui'

interface Props {
  isLoading: boolean
  commentsCount: number
  search: string
  onSearch: (search: string) => void
  downloadXlsx: () => void
}

const MatterUpdatesHeader = ({
  isLoading,
  commentsCount,
  search,
  onSearch,
  downloadXlsx
}: Props) => {
  return (
    <div className={s.container}>
      <div className={s.filtersContainer}>
        <Button isPrimary isOutline onClick={downloadXlsx}>
          Download
        </Button>
      </div>
      <div className={s.countsHeader}>
        <p>{`${pluralize('Updates', commentsCount, true)}`}</p>
        <div className={s.searchContainer}>
          <p>Search: </p>
          <TextInput
            value={search}
            placeholder="Please enter to search"
            onChange={onSearch}
            style={{ width: 220 }}
            isLoading={Boolean(isLoading)}
            testid="search_input"
            debounceDelay={500}
          />
        </div>
      </div>
    </div>
  )
}

export default MatterUpdatesHeader
