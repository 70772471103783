import s from './ShareUpdate.scss'
import { components, MultiValueGenericProps, OptionProps } from 'react-select'
import { StackText } from 'components'
import cn from 'classnames'
import { BuildingIcon, PaginatedSelect, RichTextEditor } from 'simple-core-ui'
import pluralize from 'pluralize'
import { selectDropdownStyle, selectViewerStyle } from './selectStyles'
import { formatText } from '../utils'
import { Vendor, VendorUser, VendorUserAPI } from '../types'
import { VendorListItem } from './VendorListItem'
import { toVendorSelect } from '../serializers'
import { makeGetRequest } from 'utils/api'
import { CONTACTS_SEARCH_URL } from 'contacts/utils'
import { useDispatch } from 'react-redux'
import { IoMdLock } from 'react-icons/io'
import { sortAlphabeticallyByProperty } from 'utils/helpers'

interface Props {
  setIsShareModalVisible: (arg: boolean) => void
  setBaseEditorState: (arg: string) => void
  editorState: string
  setEditorState: (arg: string) => void
  isInternalComment: boolean
  setIsInternalComment: (arg: boolean) => void
  selectedVendors: Vendor[]
  setSelectedVendors: (arg: Vendor[]) => void
  totalCgUsersCount: number
  setTotalCgUsersCount: (arg: number) => void
  mId: number
  isLoading: boolean
  withLoadingLocks: (arg: Promise<any>) => Promise<any>
}

interface SelectActionMeta {
  action: string
  option: { value: number }
  removedValue?: {
    users: VendorUser[]
  }
}

const formatOptions = (props: OptionProps<Vendor> | MultiValueGenericProps, type: string) => {
  const isLabel = type === 'label'
  const { label, userCount } = props.data

  return (
    <section className={s.optionContainer}>
      <span style={{ paddingRight: '2px' }}>
        <BuildingIcon />
      </span>
      <StackText>
        <div className={cn({ [s.stackText]: isLabel })}>
          <span className={s.labelText}>{label.toUpperCase()}</span>
          <span className={s.usersText}>
            {formatText(`${userCount} CounselGO ${pluralize('user', userCount)}`, !isLabel)}
            {props.data.isDisabled ? <IoMdLock /> : null}
          </span>
        </div>
      </StackText>
    </section>
  )
}

const Option = (props: OptionProps<Vendor>) => {
  return <components.Option {...props}>{formatOptions(props, 'option')}</components.Option>
}

const MultiValueLabel = (props: MultiValueGenericProps) => {
  return (
    <components.MultiValueLabel {...props}>
      {formatOptions(props, 'label')}
    </components.MultiValueLabel>
  )
}

const ShareUpdate = ({
  isInternalComment,
  setIsInternalComment,
  selectedVendors,
  setSelectedVendors,
  mId,
  isLoading,
  withLoadingLocks,
  editorState,
  setEditorState,
  setBaseEditorState,
  setIsShareModalVisible,
  totalCgUsersCount,
  setTotalCgUsersCount
}: Props) => {
  const dispatch = useDispatch()

  const handleVendorSelect = async (vendors: Vendor[], actionMeta: SelectActionMeta) => {
    if (actionMeta) {
      const { action, option, removedValue } = actionMeta
      if (action === 'select-option') {
        try {
          const { results } = await withLoadingLocks(
            makeGetRequest(
              `${CONTACTS_SEARCH_URL}?p=0&n=20&q=&vrn=_matter_updates&m=${mId}&v=${option.value}`
            )
          )
          const vendorUsers = results.map(({ cg_user_id, full_name }: VendorUserAPI) => ({
            cgUserId: cg_user_id,
            fullName: full_name
          }))
          const updatedVendors = vendors.map(vendor => {
            return { ...vendor, ...(vendor.value === option.value ? { users: vendorUsers } : {}) }
          })
          setSelectedVendors(sortAlphabeticallyByProperty(updatedVendors, 'label') as Vendor[])
          setTotalCgUsersCount(totalCgUsersCount + vendorUsers.length)
        } catch (error) {
          dispatch({
            type: 'API_ERROR',
            error
          })
        }
      }
      if (action === 'remove-value' && removedValue) {
        setSelectedVendors(sortAlphabeticallyByProperty(vendors, 'label'))
        setTotalCgUsersCount(totalCgUsersCount - removedValue.users.length)
      }
    }
  }

  const selectedVendorsCount = selectedVendors.length

  return (
    <div className={s.container}>
      <section className={cn(s.centerPanel, { [s.withBorder]: Boolean(selectedVendorsCount) })}>
        <p className={s.modalTitle}>
          Select who to share the matter update with<span className={s.asterix}>*</span>
        </p>
        <div className={s.radio}>
          <span className={s.option}>
            <input
              type="radio"
              checked={isInternalComment}
              onChange={() => {
                setBaseEditorState(editorState)
                setIsShareModalVisible(false)
                setSelectedVendors([])
                setIsInternalComment(true)
              }}
            />
            Internal
          </span>
          <span className={s.option}>
            <input
              type="radio"
              checked={!isInternalComment}
              onChange={() => setIsInternalComment(false)}
            />
            Internal & Vendors
          </span>
        </div>
        <RichTextEditor
          value={editorState}
          onChange={value => setEditorState(value)}
          className={s.editor}
          restrictMaxHeight
        />
        <div>
          {selectedVendorsCount ? (
            <div className={s.labelsContainer}>
              {/* @ts-expect-error */}
              <PaginatedSelect
                value={selectedVendors}
                onChange={(item: Vendor[], actionMeta: SelectActionMeta) =>
                  handleVendorSelect(item, actionMeta)
                }
                isClearable={false}
                className={s.selectViewer}
                styles={selectViewerStyle}
                isPortal={false}
                comps={{ MultiValueLabel }}
                isMulti
                serializer={toVendorSelect}
                url={`/manage/matters/referral_list/${mId}/?vrn=_matter_updates`}
                isDisabled={isLoading}
              />
              <p className={s.selectCountText}>{`${pluralize(
                'vendor',
                selectedVendorsCount,
                true
              )} selected`}</p>
            </div>
          ) : null}
          {!isInternalComment ? (
            <div>
              <p className={s.selectLabel}>
                Please select vendors from the list or start typing
                <span className={s.asterix}>*</span>
              </p>
              {/* @ts-expect-error */}
              <PaginatedSelect
                value={selectedVendors}
                onChange={(value: Vendor[], actionMeta: SelectActionMeta) =>
                  handleVendorSelect(value, actionMeta)
                }
                isClearable={false}
                className={s.selectDropdown}
                styles={selectDropdownStyle}
                isPortal={false}
                comps={{ Option }}
                isMulti
                serializer={toVendorSelect}
                placeholder="Select or Type Here"
                url={`/manage/matters/referral_list/${mId}/?vrn=_matter_updates`}
                isDisabled={isLoading}
              />
            </div>
          ) : null}
        </div>
      </section>
      {selectedVendorsCount ? (
        <section className={s.rightPanel}>
          <div className={s.panelHeader}>
            <p className={s.countText}>{`${pluralize(
              'vendor',
              selectedVendorsCount,
              true
            )} selected`}</p>
            <p className={s.description}>{`Total CounselGO users ${totalCgUsersCount}`}</p>
          </div>
          <div>
            {sortAlphabeticallyByProperty(selectedVendors, 'label').map((vendor, idx) => {
              return (
                <VendorListItem
                  key={vendor.value}
                  vendorData={vendor}
                  isLastItem={idx === selectedVendorsCount - 1}
                />
              )
            })}
          </div>
        </section>
      ) : null}
    </div>
  )
}

export default ShareUpdate
