import { useState, useEffect } from 'react'
import s from './AttributeDetails.scss'
import Select from 'react-select'
import { toAttributeDetails, fromValue, toDisplayGroups, toValueType } from './serializers'
import { makeGetRequest, makePatchRequest } from 'utils/api'
import { useDispatch } from 'react-redux'
import {
  Panel,
  KeyValuePairs,
  FlexyHalf,
  XeditableContainer,
  useLoading,
  Ellipsis
} from 'simple-core-ui'

const AttributeDetails = ({ legalEntityManagement, attribute, fetchAttribute }) => {
  const [attributeDetails, setAttributeDetails] = useState(null)
  const [scopesList, setScopesList] = useState([])
  const [displayGroups, setDisplayGroups] = useState([])
  const dispatch = useDispatch()
  const [, withLoadingLocks] = useLoading()

  useEffect(() => {
    ;(async () => {
      try {
        const results = await withLoadingLocks(
          makeGetRequest('/manage/api/matter_attributes_groups')
        )
        setDisplayGroups(toDisplayGroups(results))
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error: { ...error, errorTitle: 'Error' }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!attribute || !legalEntityManagement) return
    const [details, list] = toAttributeDetails(attribute, legalEntityManagement[0])
    setAttributeDetails(details)
    setScopesList(list)
  }, [attribute, legalEntityManagement])

  const updateAttribute = async (value, property) => {
    try {
      await withLoadingLocks(
        makePatchRequest(`/manage/api/matter_attributes/${attributeDetails.id}/`, {
          [property]: fromValue(value)
        })
      )
      // refresh the whole page to get the new columns for the scopes
      if (property === 'scopes' && attribute.field_type === 'list') {
        fetchAttribute()
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error: { ...error, errorTitle: 'Error' }
      })
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
        <a href="/manage/matter_attributes/">{'<< Back to Attributes'}</a>
      </div>
      {attributeDetails ? (
        <Panel title={<Ellipsis lines={1}>{attributeDetails.name}</Ellipsis>}>
          <FlexyHalf>
            <KeyValuePairs
              pairs={[
                {
                  key: 'Attribute Name',
                  sectionClass: [s.sectionClass],
                  value: (
                    <XeditableContainer
                      setValueCb={value => updateAttribute(value, 'name')}
                      readOnly={false}
                      type="text"
                      title="Contract Type Value"
                      initialValue={attributeDetails?.name}
                    />
                  )
                },
                {
                  key: 'ID',
                  sectionClass: [s.sectionClass],
                  value: attributeDetails?.id,
                  valueClass: [s.valueClass]
                },
                {
                  key: 'Type',
                  sectionClass: [s.sectionClass],
                  value: toValueType(attributeDetails?.type),
                  valueClass: [s.valueClass]
                },
                {
                  key: 'Display Group',
                  sectionClass: [s.sectionClass],
                  value: (
                    <XeditableContainer
                      title="Display Group"
                      setValueCb={value => updateAttribute(value, 'display_group')}
                      renderForm={(_, updateFormValue, formValue) => {
                        return (
                          <Select
                            value={formValue}
                            onChange={updateFormValue}
                            options={displayGroups}
                          />
                        )
                      }}
                      readOnly={false}
                      type="list"
                      initialValue={attributeDetails?.displayGroup}
                    />
                  )
                }
              ]}
            />
            <KeyValuePairs
              pairs={[
                {
                  key: 'Description',
                  sectionClass: [s.sectionClass],
                  value: (
                    <XeditableContainer
                      title="Contract Type Description"
                      setValueCb={value => updateAttribute(value, 'description')}
                      readOnly={false}
                      type="text"
                      initialValue={attributeDetails?.description}
                    />
                  )
                },
                {
                  key: 'Attribute Scope',
                  sectionClass: [s.sectionClass],
                  value: (
                    <XeditableContainer
                      title="Field Type"
                      setValueCb={value => {
                        updateAttribute(value ?? [], 'scopes')
                      }}
                      renderForm={(_, updateFormValue, formValue) => {
                        return (
                          <Select
                            value={formValue}
                            onChange={v => updateFormValue(v.length ? v : null)}
                            isMulti
                            options={scopesList}
                          />
                        )
                      }}
                      readOnly={false}
                      type="list"
                      initialValue={
                        attributeDetails?.scopes.length ? attributeDetails?.scopes : null
                      }
                    />
                  )
                },
                {
                  key: 'Allocatable',
                  sectionClass: [s.sectionClass],
                  valueClass: attributeDetails?.type === 'list' ? '' : [s.valueClass],
                  value:
                    attributeDetails?.type === 'list' ? (
                      <XeditableContainer
                        title="Make Attribute Allocatable?"
                        setValueCb={value => updateAttribute(value.value, 'is_allocatable')}
                        renderForm={(_, updateFormValue, formValue) => {
                          return (
                            <Select
                              value={formValue}
                              onChange={updateFormValue}
                              options={[
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' }
                              ]}
                            />
                          )
                        }}
                        readOnly={attributeDetails?.isToggable ? false : true}
                        type="list"
                        initialValue={attributeDetails?.isAllocatable}
                      />
                    ) : (
                      'N/A'
                    )
                }
              ]}
            />
          </FlexyHalf>
        </Panel>
      ) : null}
    </>
  )
}

export default AttributeDetails
