import { StylesConfig } from 'react-select'

export const selectDropdownStyle: StylesConfig = {
  control: base => ({
    ...base,
    backgroundColor: 'white',
    minBlockSize: 60
  }),
  multiValue: styles => ({
    ...styles,
    display: 'none'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  })
}

export const selectViewerStyle: StylesConfig = {
  input: () => ({ display: 'none' }),
  indicatorsContainer: () => ({ display: 'none' }),
  valueContainer: base => ({
    ...base,
    backgroundColor: 'white',
    overflowY: 'scroll',
    alignContent: 'baseline',
    padding: '10px',
    borderRadius: '3px',
    maxHeight: '140px'
  }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: 'white',
    border: '0.5px solid #040404',
    borderRadius: '3px'
  })
}
